<template>
  <div ref="wiki" :class="cclass" class="mcr-wiki">
    <div class="wiki-content-wrapper" v-if="!loading">
      <h5 v-if="title">{{ title }}</h5>
      <template v-if="formatPic">
        <div class="wiki-pic">
          <img v-if="isJLTReport" :src="formatPic" />
          <img v-else :src="imgPreviewSrc" :data-src="pic" class="lazyload wiki-pic" />
        </div>
      </template>
      <div>
        <!-- this div required for dynamic content to stay in this place -->
        <div :id="dynamicContentId" :ref="dynamicContentId"></div>
      </div>
      <a v-if="wikiUrl" class="wiki-read-more" :href="wikiUrl" target="__blank">Read More</a>
    </div>
    <mcr-loading-indicator :loading="loading" />
  </div>
</template>

<script>
import {NetworkAPIThumbnailURL} from '@common/network/network.utils';
import MdHtmlConverter from '@common/utils/showdown/MdHtmlConverter';
import McrCoverter from '@common/utils/showdown/jlt_report/mcrCoverter';
import {getImageLazyLoadPlaceholderSrc} from '@common/utils/utils';
import moment from 'moment';
import Vue from 'vue';

export default {
  props: ['cclass', 'mdContent', 'title', 'pic', 'wikiUrl', 'loading'],
  data() {
    return {
      dynamicContentId: `dynamic-content-${moment().unix() + Math.random().toString(36).substr(2)}`,
      imgWidth: 0,
      dynamicComponent: null,
    };
  },
  watch: {
    loading(value) {
      if (!value) {
        this.renderHtml();
      }
    },
  },
  computed: {
    formatPic() {
      if (this.pic && this.imageWidth > 0) {
        return NetworkAPIThumbnailURL + '?url=' + encodeURIComponent(this.pic) + '&width=' + this.imageWidth;
      }
    },
    isLoading() {
      return this.loading !== 'undefined' && this.loading !== undefined && this.loading;
    },
    isJLTReport() {
      // old jlt report was refactored to be familyWebsite, keeping logic for old reports, until they are migrated
      return this.$route.meta && this.$route.meta.isJLTReport;
    },
    imgPreviewSrc() {
      return getImageLazyLoadPlaceholderSrc(this.pic);
    },
  },
  methods: {
    createJLTReportDynamicComponent() {
      // username only available on report page
      let html = McrCoverter.makeHtml(this.mdContent, this.$route.params.username);
      return Vue.extend({
        template: '<div class="wiki-content">' + html + '</div>',
        name: 'mcr-report-wiki-page',
        components: {
          McrGallery: () => import('@common/elements/familyWebsite/jlt/mcr_gallery'),
          McrHeader: () => import('@common/elements/familyWebsite/jlt/mcr_header'),
          McrImage: () => import('@common/elements/familyWebsite/jlt/mcr_image'),
          McrHalfColumnImages: () => import('@common/elements/familyWebsite/jlt/mcr_half_column_images'),
          McrFacts: () => import('@common/elements/familyWebsite/jlt/mcr_facts'),
        },
      });
    },
    createFamilyWebsiteDynamicComponent() {
      let html = MdHtmlConverter.makeHtml(this.mdContent, this.$route.params.username, 'mcr-wiki-');
      return Vue.extend({
        template: '<div class="wiki-content">' + html + '</div>',
        name: 'mcr-report-wiki-page',
        components: {
          McrImage: () => import('@common/elements/familyWebsite/McrImage'),
          McrHalfColumnImages: () => import('@common/elements/familyWebsite/McrHalfColumnImages'),
        },
      });
    },
    createDynamicComponent() {
      if (this.isJLTReport) {
        return this.createJLTReportDynamicComponent();
      }
      return this.createFamilyWebsiteDynamicComponent();
    },
    renderHtml() {
      if (!this.mdContent) {
        return;
      }
      let comp = this.createDynamicComponent();

      this.dynamicComponent = new comp();
      this.$nextTick(() => {
        this.mountDynamicComponent();
      });
      this.setImageWidth();
    },
    setImageWidth() {
      if (this.$refs.wiki && this.$refs.wiki.clientWidth && this.$refs.wiki.clientWidth > 0) {
        this.imageWidth = this.$refs.wiki.clientWidth;
      } else {
        setTimeout(() => {
          this.setImageWidth();
        }, 500);
      }
    },
    mountDynamicComponent() {
      this.dynamicComponent.$mount(this.$refs[this.dynamicContentId]);
    },
  },
};
</script>
<style scoped lang="scss">
.mcr-wiki {
  min-height: 100px;
  .wiki-pic {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
  }

  .wiki-content-wrapper {
    .wiki-read-more {
      display: flex;
      align-items: center;
      margin-top: 8px;
      .material-design-icon {
        position: relative;
        top: 1px;
        margin-left: 4px;
      }
    }
  }
}
</style>
